import { Flex, Text, useToast, VStack, Checkbox } from '@chakra-ui/react'
import { generateId } from 'controllers/db'
import { dbFetchItemsList, dbGetItemsBySlot } from 'controllers/items'
import _ from 'lodash'
import {
  addMattertag,
  addToRecentlyDeleted,
  removeFromRecentlyDeleted,
  removeItem,
  removeLoadingItem,
  replaceItem,
  setItems,
  updateItem,
  addLoadingItems,
  clearLoadingItems
} from 'model/actions'
import { useDispatch, useSelector } from 'model/hooks'
import { getRoomTypesWithTemplates } from 'model/selectors/templatesSelector'
import EditTourNavBar from 'pages/tour/EditTourNavBar'
import Panel from 'pages/tour/Panel'
import { useEffect, useMemo, useRef } from 'react'
import { useDeviceSelectors } from 'react-device-detect'
import TourView from 'shared/components/TourView'
import { AddItemT, TourSlotT } from 'types/model'

const Tour = () => {
  const [{ isMobile, isEmbedded }] = useDeviceSelectors(
    window.navigator.userAgent
  )
  const tourModel = useSelector(state => state.tourModel)
  const userItems = useSelector(state => state.userItems)
  const items = useSelector(state => state.items)
  const slots = useSelector(state => state.slots)
  const suppliers = useSelector(state => state.suppliers)
  const mattertags = useSelector(state => state.mattertags)
  const tours = useSelector(state => state.tours)
  const appMode = useSelector(state => state.mode)
  const partner = useSelector(state => state.partner)
  const accountProfile = useSelector(state => state.accountProfile)
  const roomTypesWithTemplates = useSelector(getRoomTypesWithTemplates)
  const dispatch = useDispatch()
  const itemsNotFoundRef = useRef<string[]>([])
  const toast = useToast()

  const canEdit = useMemo(() => {
    return !isEmbedded
  }, [isEmbedded])

  const tour = useMemo(() => {
    if (tours && appMode.tourId) {
      return tours[appMode.tourId]
    }
  }, [appMode, tours])

  useEffect(() => {
    const run = async () => {
      const itemsIdsToLoad = _.uniq(_.map(userItems, ts => ts.itemId))
      console.log('itemsIdsToLoad', itemsIdsToLoad)
      const currentIds = _.keys(items)
      console.log('currentIds', currentIds)
      const diff = _.difference(itemsIdsToLoad, currentIds)
      console.log(
        'diff',
        diff,
        'current itemsNotFound',
        itemsNotFoundRef.current
      )
      const finalDiff = _.difference(diff, itemsNotFoundRef.current)
      console.log('finalDiff', finalDiff)
      if (!_.isEmpty(finalDiff)) {
        dispatch(addLoadingItems(finalDiff))
        const nf = await dbFetchItemsList(finalDiff)
        itemsNotFoundRef.current = _.uniq([...itemsNotFoundRef.current, ...nf])
      }
    }
    run()
  }, [items, userItems])

  const removeTourSlot = (slotId: string) => {
    const ts = userItems[slotId]
    if (ts) {
      dispatch(removeItem(slotId))
      dispatch(addToRecentlyDeleted(ts.itemId))
    }
  }

  const updateTourSlot = async (
    slotId: string,
    key: keyof TourSlotT,
    value: any
  ) => {
    dispatch(updateItem({ tourSlotId: slotId, update: { [key]: value } }))
  }

  const onAddItems = (aItems: AddItemT[]) => {
    _.forEach(aItems, ({ itemId, position, rotation }: AddItemT) => {
      const id = generateId()
      const newItem: TourSlotT = {
        id,
        itemId,
        position,
        createdAt: _.now(),
        rotation
      }
      dispatch(replaceItem(newItem))
      dispatch(removeFromRecentlyDeleted(itemId))
    })
  }
  const onResetDesign = () => {
    dispatch(setItems({}))
    dispatch(clearLoadingItems())
  }

  const onMattertagCreated = (tourSlotId: string, mtId: string) => {
    dispatch(addMattertag({ tourSlotId, tagId: mtId }))
  }

  const onItemLoaded = (id: string) => {
    dispatch(removeLoadingItem(id))
  }

  const tipName = 'tip_move_by_arrow_keys'

  const showToast = () => {
    const hideTip = localStorage.getItem(tipName)
    if (_.isNil(hideTip) && !isMobile) {
      toast({
        title: 'Tip',
        variant: 'left-accent',
        isClosable: true,
        status: 'info',
        position: 'top',
        description: (
          <VStack w='full' align='flex-start'>
            <Text>
              Click &apos;Open 3D Catalog&apos; in the top right to start designing.
            </Text>
            <Checkbox
              colorScheme={'blackAlpha'}
              size='sm'
              borderColor='black'
              onChange={e =>
                localStorage.setItem(tipName, _.toString(e.target.checked))
              }
            >
              Do not show this again
            </Checkbox>
          </VStack>
        ),
        duration: 10000
      })
    }
  }

  if (tourModel && !_.isNil(items)) {
    return (
      <Flex w='full' h='full' position='relative'>
        <TourView
          tourSlots={userItems}
          modelId={tourModel.modelId}
          tourId={tour ? tour.id : 'unknown'}
          tourModelId={tourModel.id}
          deleteSlot={removeTourSlot}
          updateTourSlot={updateTourSlot}
          onResetDesign={onResetDesign}
          addItems={onAddItems}
          items={items}
          slots={slots}
          suppliers={suppliers}
          mattertags={mattertags}
          EditTourNavBar={EditTourNavBar}
          onMattertagCreated={onMattertagCreated}
          UserItemsPanel={Panel}
          designName={tour ? tour.name : 'Your Items'}
          roomTypesWithTemplates={roomTypesWithTemplates}
          loadItems={(itemsIds: string[]) => dbFetchItemsList(itemsIds)}
          getItems={dbGetItemsBySlot}
          customerLogoUrl={accountProfile?.logoUrl}
          canEdit={canEdit}
          pricesVisible={_.get(partner, 'pricesVisible', false)}
          onItemLoaded={onItemLoaded}
          onUserMove={showToast}
        />
      </Flex>
    )
  } else {
    return <Text>loading</Text>
  }
}

export default Tour
